<template>
  <v-container
          id="collections"
          fluid
          tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
        <base-material-card
                color="green">
          <template v-slot:heading>
            <h1 class="display-2 font-weight-regular">
              컬렉션 관리
            </h1>
          </template>
          <v-card-text>
            <v-row align="center" class="mb-5">
                <v-btn color="default" @click="beforePage">이전</v-btn>
            </v-row>
            <template v-if="resultContents">
              <v-row justify="space-around" align="center">
                <v-col cols="5" class="pb-0 pt-0">
                  <v-row class="mb-0" align="center">
                    <v-col cols="4" class="pt-0">
                      <v-select
                              :items="config.partnersOptions"
                              v-model="vModel.partner"
                              label="CP명"
                              color="secondary"
                              @change="submitKeyword"/>
                    </v-col>
                    <v-col cols="4" class="pt-0">
                      <form @submit.prevent="submitKeyword">
                        <v-text-field
                                v-model="keyword"
                                append-icon="mdi-magnify"
                                class="ml-auto pt-0"
                                label="Search"
                                color="secondary"
                                style="margin-top:-6px"
                                hide-details
                                single-line />
                      </form>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="1" class="pb-0">
                </v-col>
                <v-col cols="6" class="pb-0">
                  <template v-if="resultCollections && resultContents">
                    <v-row justify="space-between" align="center">
                      <v-col>
                      <v-btn @click="goUpDown('up')" small><v-icon>mdi-arrow-up-bold</v-icon></v-btn>
                      <v-btn @click="goUpDown('down')" small><v-icon>mdi-arrow-down-bold</v-icon></v-btn>
                      </v-col>
                      <v-col cols="2">
                      <v-select
                              :items="config.perOptions"
                              v-model="vModel.per"
                              label="페이지당 컨텐츠 수"
                              color="secondary"
                              @change="perPageClick"/>
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col cols="5">
                  <div style="max-height:540px;overflow-y: auto;border: 1px solid rgba(0, 0, 0, 0.12);"
                       v-on:scroll="infiniteHandler">
                    <div ref="contentCols" v-if="resultContents.length > 0">
                      <v-data-table
                              v-model="vModel.listSelected1"
                              :headers="config.headers1"
                              :items="resultContents"
                              :items-per-page="resultContents.length"
                              item-key="num"
                              no-data-text="리스트가 없습니다."
                              @click:row="tableRowClick"
                              show-select
                              hide-default-footer>
                        <template v-slot:item.title="{ item }">
                          <p class="text-left ma-0">{{ item.title }}</p>
                        </template>
                        <template v-slot:item.category="{ item }">
                          <p style="width:70px;">{{ item.category }}</p>
                        </template>
                        <template v-slot:item.created="{ item }">
                          <p style="width:80px;">{{ item.created.slice(0, 10) }}</p>
                        </template>
                        <template v-slot:item.partner.name="{ item }">
                          <p style="width:65px;">{{ item.partner.name }}</p>
                        </template>
                      </v-data-table>
                    </div>
                  </div>
                </v-col>
                <v-col cols="1" class="text-center">
                  <v-btn
                          style="position: relative; top: 50%; margin-top: -20px !important;"
                          class="ma-1"
                          color="warning"
                          @click="collectionClick"
                          :disabled="this.vModel.listSelected1.length === 0"
                          fab
                          small>
                    <v-icon center>
                      mdi-arrow-right-bold
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="6">
                  <template v-if="resultCollections.meta && resultContents">
                    <v-row style="max-height:540px;overflow-y: auto;border: 1px solid rgba(0, 0, 0, 0.12);">
                      <v-col cols="12">
                        <v-data-table
                                v-model="vModel.listSelected2"
                                :headers="config.headers2"
                                :items="resultCollections.collection"
                                :items-per-page="Number(resultCollections.meta.perPage)"
                                item-key="num"
                                no-data-text="리스트가 없습니다."
                                @click:row="tableRowClick2"
                                show-select
                                single-select
                                hide-default-footer>
                          <template v-slot:item.no="{ item }">
                            {{ (Number(resultCollections.meta.perPage) * (resultCollections.meta.currentPage - 1)) + item.num + 1 }}
                          </template>
                          <template v-slot:item.title="{ item }">
                            <p class="text-left ma-0">{{ item.title }}</p>
                          </template>
                          <template v-slot:item.category="{ item }">
                            <p style="width:70px;">{{ item.category }}</p>
                          </template>
                          <template v-slot:item.created="{ item }">
                            <p style="width:80px;">{{ item.created.slice(0, 10) }}</p>
                          </template>
                          <template v-slot:item.partner.name="{ item }">
                            <p style="width:65px;">{{ item.partner.name }}</p>
                          </template>
                          <template v-slot:item.delete="{ item }">
                            <v-btn small @click="listClose(item.key)"><v-icon>mdi-delete</v-icon></v-btn>
                          </template>
                        </v-data-table>
                      </v-col>
                    </v-row>
                    <v-pagination
                            v-model="vModel.page"
                            :length="resultCollections.meta.lastPage"
                            :total-visible="10"
                            @input="paginationClick"
                            circle
                            class="justify-center mt-5"
                            next-icon="mdi-menu-right"
                            prev-icon="mdi-menu-left"/>
                    <v-row justify="center" v-if="false">
                      <v-btn
                              class="mt-5"
                              color="success"
                              @click="submitSelected('save')">
                        저장
                      </v-btn>
                      <v-btn
                              class="mt-5"
                              color="default"
                              @click="cancelSelected">
                        취소
                      </v-btn>
                    </v-row>
                  </template>
                  <template v-else>
                    <p>지정된 컨텐츠가 없습니다.</p>
                  </template>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
            v-model="vModel.snackbar"
            :type="vModel.snackbarType"
            v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  created () {
    this.refresh()
  },
  data () {
    return {
      page: 1,
      response: {
        contents: null,
        media: null,
        mediaList: null,
        inventory: null,
        contentsList: null,
        collections: null,
        collectionsAll: null,
        lastPage: 2
      },
      keyword: null,
      vModel: {
        listSelected1: [],
        listSelected2: [],
        resultListSelected: null,
        resultListChk: [],
        collections: [],
        media: '',
        partner: '',
        page: 1,
        per: 20
      },
      config: {
        linkPartners: [],
        partnersOptions: [],
        perOptions: [ 20, 50, 100 ],
        headers1: [
          { text: '제목', value: 'title', align: 'center' },
          { text: '종류', value: 'category', align: 'center' },
          { text: '등록일', value: 'created', align: 'center' },
          { text: 'CP명', value: 'partner.name', align: 'center' },
          //  text: '본문', value: 'page', align: 'center' },
        ],
        headers2: [
          // { text: '', value: 'drag', align: 'center' },
          { text: 'No', value: 'no', align: 'center' },
          { text: '제목', value: 'title', align: 'center' },
          { text: '종류', value: 'category', align: 'center' },
          { text: '등록일', value: 'created', align: 'center' },
          { text: 'CP명', value: 'partner.name', align: 'center' },
          { text: '제외', value: 'delete', align: 'center' },
        ]
      }
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    beforePage () {
      this.$router.go(-1)
    },
    infiniteHandler (value) {
      let height = this.$refs.contentCols.clientHeight - 550
      let currentScrollPosition = value.srcElement.scrollTop
      if (height < currentScrollPosition && this.page < this.response.lastPage) {
        this.page++
        this.contentsList()
      }
    },
    tableRowClick (val) {
      let arr = this.vModel.listSelected1.filter(function ($value) {
        return $value.key === val.key
      })
      if (arr.length > 0) {
        let result = this.vModel.listSelected1.filter(function ($value) {
          return $value.key !== val.key
        })
        this.vModel.listSelected1 = result
      } else {
        this.vModel.listSelected1.push(val)
      }
    },
    tableRowClick2 (val) {
      if (this.vModel.listSelected2.length > 0) {
        if (this.vModel.listSelected2[0].key === val.key) {
          this.$set(this.vModel.listSelected2, 0, {})
        } else {
          this.$set(this.vModel.listSelected2, 0, val)
        }
      } else {
        this.$set(this.vModel.listSelected2, 0, val)
      }
    },
    paginationClick (page) {
      this.vModel.page = page
      let send = {
        page: page
      }
      if (this.$route.query.per) {
        this.vModel.per = this.$route.query.per
        send.per = this.$route.query.per
      }
      if (this.keyword) {
        send.keyword = this.keyword
      }
      if (this.vModel.partner) {
        send.partner = this.vModel.partner
      }
      if (['InventoryCollection'].includes(this.$route.name)) {
        this.$router.replace({ name: 'InventoryCollection', query: send })
      } else {
        this.$router.replace({ name: 'mediaCollection', query: send })
      }
      this.collectionsList()
    },
    perPageClick () {
      let send = {
        page: 1,
        per: this.vModel.per
      }
      if (this.keyword) {
        send.keyword = this.keyword
      }
      if (this.vModel.partner) {
        send.partner = this.vModel.partner
      }
      if (['InventoryCollection'].includes(this.$route.name)) {
        this.$router.replace({ name: 'InventoryCollection', query: send })
      } else {
        this.$router.replace({ name: 'mediaCollection', query: send })
      }
      this.collectionsList()
    },
    goUpDown (val) {
      let send = {
        rank: val
      }
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id + '/' + this.vModel.listSelected2[0].key,
        method: 'put',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.snack('warning', '컨텐츠 배열이 수정되었습니다.')
          this.vModel.listSelected2 = []
          this.collectionsList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    upStep () {
      // 전체 값
      let arr = this.vModel.collections.collection
      // 체크된 값
      let data = this.vModel.listSelected2
      this.vModel.listSelected2 = []
      // 체크된 값들의 번째 빼내기
      let indexArr = data.map(function ($value) {
        return $value.num
      })
      if (indexArr[0] > 0) {
        // 제일 처음 값이 0번째가 아닐 때
        // 해당 번째 값을 null로
        for (let i = 0; i < indexArr.length + 1; i++) {
          arr[indexArr[i]] = null
        }
        // 체크되지 않은 값들만 담기
        arr = arr.filter(n => n)
        // 체크한 값들이 빠져나간 곳의 뒤의 값들을 따로 담기
        let a = arr.splice(indexArr[0], arr.length - 1)
        // 체크한 값들 중 제일 먼저 있었던 자리보다 1개 앞에 값들 넣기
        let num = -1
        for (let i = 0; i < indexArr.length; i++) {
          arr.splice(indexArr[0] + num, 0, data[i])
          this.vModel.listSelected2.push(data[i])
          this.vModel.listSelected2[i].num = indexArr[0] + num
          num++
        }
        // 뒤의 값들을 배열 뒤에 붙이기
        arr = arr.concat(a)
        // 다시 순서값 부여
        for (let i = 0; i < arr.length; i++) {
          arr[i].num = i
        }
        this.vModel.collections.collection = arr
      } else {
        this.vModel.listSelected2 = data
      }
    },
    downStep () {
      // 전체 값
      let arr = this.vModel.collections.collection
      // 체크된 값
      let data = this.vModel.listSelected2
      this.vModel.listSelected2 = []
      // 체크된 값들의 번째 빼내기
      let indexArr = data.map(function ($value) {
        return $value.num
      })
      if (indexArr[indexArr.length - 1] < arr.length - 1) {
        // 제일 처음 값이 0번째가 아닐 때
        // 해당 번째 값을 null로
        for (let i = 0; i < indexArr.length + 1; i++) {
          arr[indexArr[i]] = null
        }
        // 체크한 값 뒤의 값들을 따로 담기
        let a = arr.splice(indexArr[indexArr.length - 1] + 2, this.vModel.collections.collection.length - 1)
        // 체크되지 않은 값들만 담기
        arr = arr.filter(n => n)
        // 체크되지 않은 값들 수
        let length = arr.length
        // 체크되지 않은 값들 뒤에 체크했던 값 붙이기
        arr = arr.concat(data)
        // 체크값 v-model 재설정
        for (let i = 0; i < indexArr.length; i++) {
          this.vModel.listSelected2.push(data[i])
          this.vModel.listSelected2[i].num = length
          length++
        }
        // 배열에 체크 뒤에 있던 값 붙이기
        arr = arr.concat(a)
        // 다시 순서값 부여
        for (let i = 0; i < arr.length; i++) {
          arr[i].num = i
        }
        this.vModel.collections.collection = arr
      } else {
        this.vModel.listSelected2 = data
      }
    },
    submitSelected (val) {
      if (confirm('저장하겠습니까?')) {
        let data = this.vModel.collections.collection
        let key = data.map(function ($value) {
          return { key: $value.key }
        })
        let send = {
          contents: key
        }
        let media
        if (['InventoryCollection'].includes(this.$route.name)) {
          media = this.response.mediaList[0].key
        } else {
          media = this.$route.params.media_id
        }
        this.xhttp({
          url: '/' + media + '/collections/' + this.$route.params.inventory_id,
          method: 'put',
          data: send
        }).then((response) => {
          if (response.status === 200) {
            this.vModel.listSelected2 = []
            if (val === 'close') {
              this.snack('success', '제외 완료되었습니다.')
              this.vModel.listSelected2 = []
              this.contentsList('refresh')
              this.collectionsList()
            } else {
              this.snack('success', '저장 완료되었습니다.')
            }
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    cancelSelected () {
      this.vModel.listSelected2 = []
      this.contentsList('refresh')
      this.collectionsList()
    },
    collectionClick () {
      let key1 = this.response.collectionsAll.map(function ($value) {
        return { key: $value.contents_key }
      })
      let key2 = this.vModel.listSelected1.map(function ($value) {
        return { key: $value.key }
      })
      let arr = key1.concat(key2)
      let send = {
        contents: arr
      }
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id,
        method: 'put',
        data: send
      }).then((response) => {
        if (response.status === 200) {
          this.snack('warning', '컨텐츠가 추가 되었습니다.')
          this.vModel.listSelected1 = []
          this.vModel.listSelected2 = []
          this.contentsList('refresh')
          this.collectionsList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    collectionClick2 () {
      let chk = this.vModel.listSelected1
      for (let j = 0; j < chk.length; j++) {
        this.response.contents.contents = this.response.contents.contents.filter(function ($value) {
          return $value.key !== chk[j].key
        })
      }
      let num = this.vModel.collections.collection.length
      for (let i = 0; i < chk.length; i++) {
        chk[i].num = num + i
      }
      this.vModel.collections.collection = this.vModel.collections.collection.concat(chk)
      this.vModel.listSelected1 = []
    },
    submitKeyword () {
      let send = {}
      if (this.keyword) {
        send.keyword = this.keyword
      }
      if (this.vModel.partner) {
        send.partner = this.vModel.partner
      }
      if (this.$route.query.page) {
        this.vModel.page = Number(this.$route.query.page)
        send.page = this.vModel.page
      }
      if (this.$route.query.per) {
        this.vModel.per = this.$route.query.per
        send.per = this.vModel.per
      }
      if (['InventoryCollection'].includes(this.$route.name)) {
        this.$router.replace({ name: 'InventoryCollection', query: send })
      } else {
        this.$router.replace({ name: 'mediaCollection', query: send })
      }
      this.contentsList('refresh')
    },
    numReplace (val) {
      let num = val + ''
      const result = num.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return result
    },
    listClose (val) {
      if (confirm('제외하겠습니까?')) {
        this.vModel.collections.collection = this.vModel.collections.collection.filter(function ($value) {
          return $value.key !== val
        })
        this.submitSelected('close')
      }
    },
    refresh () {
      if (this.$route.query.page) {
        this.vModel.page = Number(this.$route.query.page)
      }
      if (this.$route.query.per) {
        this.vModel.per = Number(this.$route.query.per)
      }
      if (this.$route.name === 'InventoryCollection') {
        this.mediaList()
      } else {
        this.getMedia()
        this.getInventory()
      }
    },
    getInventory () {
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/inventory/' + this.$route.params.inventory_id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.inventory = response.data.data
          this.allCollectionsList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    mediaList () {
      let send = {
        orderby: 'created',
        direction: 'desc'
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.mediaList = response.data.data.media
          this.getMedia()
          this.getInventory()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    contentsList (val) {
      this.response.contents = null
      let send = {
        per: 20,
        page: this.page,
        orderby: 'created',
        direction: 'asc',
        on: 'all',
      }
      if (this.$route.query.keyword) {
        this.keyword = this.$route.query.keyword
        send.keyword = this.$route.query.keyword
        send.where = 'title'
      }
      if (this.$route.query.partner) {
        this.vModel.partner = this.$route.query.partner
        send.partner = this.$route.query.partner
      }
      this.xhttp({
        url: '/inventory/' + this.$route.params.inventory_id + '/contents',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.contents = response.data.data
          this.response.lastPage = this.response.contents.meta.lastPage
          for (let i = 0; i < this.response.contents.contents.length; i++) {
            this.response.contents.contents[i].num = i + (this.page * 100)
          }
          if (val === 'refresh') {
            this.response.contentsList = this.response.contents.contents
          } else {
            this.response.contentsList = this.response.contentsList.concat(this.response.contents.contents)
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getMedia () {
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/media/' + media,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          this.config.linkPartners = null
          if (this.response.media.linkPartner.length > 0) {
            this.config.linkPartners = this.response.media.linkPartner.map(function (val) {
              if (val.partner_approved === 1) {
                return { value: val.partner_id, text: val.partner.name }
              }
            })
            this.getPartners()
          }
          this.contentsList('refresh')
          this.collectionsList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    getPartners () {
      let send = {
        per: 999,
        page: 1,
        orderby: 'created',
        direction: 'desc'
      }
      this.xhttp({
        url: '/partners',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.partner = response.data.data.partners
          for (let i = 0; i < this.response.partner.length; i++) {
            for (let j = 0; j < this.config.linkPartners.length; j++) {
              if (this.response.partner[i].id === this.config.linkPartners[j].value) {
                this.config.partnersOptions.push({ value: this.response.partner[i].key, text: this.response.partner[i].name })
              }
            }
          }
          this.config.partnersOptions.unshift({ value: '', text: '전체' })
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    collectionsList () {
      this.response.collections = null
      this.vModel.collections.collection = []
      let send = {
        page: this.vModel.page,
        per: this.vModel.per
      }
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.collections = response.data.data
          if (this.response.collections.collection) {
            for (let i = 0; i < this.response.collections.collection.length; i++) {
              this.response.collections.collection[i].num = i
            }
            this.vModel.collections = this.response.collections
          }
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    allCollectionsList () {
      let send = {
        page: 1,
        per: this.response.inventory.limit
      }
      let media
      if (['InventoryCollection'].includes(this.$route.name)) {
        media = this.response.mediaList[0].key
      } else {
        media = this.$route.params.media_id
      }
      this.xhttp({
        url: '/' + media + '/collections/' + this.$route.params.inventory_id,
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.collectionsAll = response.data.data.collection
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    }
  },
  computed: {
    resultContents () {
      if (this.response.contentsList) {
        return this.response.contentsList
      } else {
        return false
      }
    },
    resultCollections () {
      if (this.vModel.collections) {
        return this.vModel.collections
      } else {
        return false
      }
    },
    resultCollections2 () {
      if (this.vModel.resultListSelected) {
        return this.vModel.resultListSelected
      } else {
        return false
      }
    }
  },
  watch: {
    keyword: function (val) {
      if (val === '') {
        this.submitKeyword()
      }
    }
  }
}
</script>
